import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, throwError } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { TrackingService } from '../../core/services/tracking.service';
import { SignInQueryParams } from '../sign-in/sign-in-query-params.interface';
import { SignupErrorState } from './signup-error-state.enum';
import { TargetAppConfigService } from '../../core/services/target-app-config.service';
import { TargetAppConfig } from '../../core/models/target-app-config.model';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  emailForm: FormControl;

  errorState: SignupErrorState;

  signupErrorState: typeof SignupErrorState = SignupErrorState;

  isFreeDomainEmail: boolean;

  showLoaderOnButton: boolean;

  signUpMessage: {
    line1: string;
    line2: string;
  };

  private queryParams: SignInQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private window: Window,
    private trackingService: TrackingService,
    private routerAuthService: RouterAuthService,
    private authService: AuthService,
    private targetAppConfigService: TargetAppConfigService
  ) {
    this.emailForm = new FormControl('', [Validators.required, Validators.email]);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 400) {
      this.router.navigate(['request_invitation'], { queryParams: { email: this.emailForm.value } });
    } else if (error.status === 500) {
      this.errorState = SignupErrorState.UNKNOWN_ERROR;
    } else {
      this.errorState = SignupErrorState.UNAUTHORIZED;
    }
  }

  private goToEnterprise() {
    const queryParams = this.queryParams;
    const params = {
      email: queryParams.email || this.emailForm.value,
      persona: 'enterprise',
      asset: queryParams.asset,
      utm_source: queryParams.utm_source,
      utm_medium: queryParams.utm_medium,
      utm_campaign: queryParams.utm_campaign,
      redirect_uri: queryParams.redirect_uri,
      referrer: queryParams.referrer,
    };

    this.router.navigate(['pre_verification/signup_details'], { queryParams: params });
  }

  private setParamsAndRedirect() {
    // generating user properties and raising an event signup started
    this.trackingService.canSignup(this.emailForm.value);
    this.goToEnterprise();
  }

  private setSignupMessage() {
    const signUpMessages = [
      {
        line1: 'Effortless expense management',
        line2: 'for fast-growing businesses.',
      },
      {
        line1: 'Modern software that saves time,',
        line2: 'money & earns employee love.',
      },
    ];

    const randomIndex = Math.floor(Math.random() * 2);

    this.signUpMessage = signUpMessages[randomIndex];
  }

  private canSignup() {
    this.routerAuthService
      .canSignup(this.emailForm.value)
      .pipe(
        catchError((error) => {
          this.handleError(error as HttpErrorResponse);
          return throwError(() => error);
        }),
        finalize(() => {
          this.showLoaderOnButton = false;
        })
      )
      .subscribe((resp) => {
        this.setParamsAndRedirect();
      });
  }

  basicAuthSignup() {
    if (this.emailForm.invalid) {
      return;
    }

    this.showLoaderOnButton = true;
    this.errorState = null;
    this.canSignup();
  }

  goToSignIn() {
    // generating url params while migrating
    const params = cloneDeep(this.queryParams);
    params.email = this.emailForm.value || params.email;
    this.router.navigate(['signin'], { queryParams: params });
  }

  ngOnInit() {
    this.queryParams = this.activatedRoute.snapshot.queryParams as SignInQueryParams;

    /**
     * If the user is logged in, then take the user to signin page.
     * Signin page will take care of redirecting to switch_org page.
     */
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['signin']);
      return;
    }

    /** if the user comes from website inline signup, check for email in url */
    if (this.queryParams.email) {
      this.emailForm.setValue(this.window.decodeURIComponent(this.queryParams.email));
      /** redirecting to persona page with url params  */
      this.setParamsAndRedirect();
      return;
    }

    this.targetConfig = this.targetAppConfigService.getTargetConfig();

    this.setSignupMessage();

    /**
     * if Org signup directly in Flow Flutterwave won't have the data about the org
     * hence redirect the user to sign in page if they try to signup in Flow
     */
    if (this.targetConfig.TARGET === 'flow') {
      this.router.navigate(['signin']);
      return;
    }

    this.emailForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(() => this.emailForm.valid)
      )
      .subscribe((resp) => {
        this.isFreeDomainEmail = this.routerAuthService.checkIfFreeDomain(this.emailForm.value);
      });
  }
}
