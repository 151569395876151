import { Injectable, Inject } from '@angular/core';
import { Observable, of, map, switchMap, BehaviorSubject } from 'rxjs';
import { ROOT_URL } from '../../root-url.token';
import { AuthPayload } from '../models/auth-payload.model';
import { AuthResponse } from '../models/auth-response.model';
import { CanSignupPayload } from '../models/can-signup-payload.model';
import { EmailVerifyPayload } from '../models/email-verify-payload.model';
import { GoogleSignInPayload } from '../models/google-sing-in-payload.model';
import { RegionResponse } from '../models/region.model';
import { ResendEmailVerificationResponse } from '../models/resend-email-verification-response.model';
import { ResendEmailVerificationPayload } from '../models/resent-email-verification-payload.model';
import { SendResetPasswordPayload, ResetPasswordPayload } from '../models/reset-password.model';
import { SecondaryEmailVerifyPayload } from '../models/secondary-email-verify-payload.model';
import { SecondaryEmailVerifyResponse } from '../models/secondary-email-verify-response.model';
import { SignInPayload } from '../models/sign-in-payload.model';
import { SignInResponse } from '../models/sign-in-response.model';
import { SignupParams } from '../models/signup-params.model';
import { SignupPayload } from '../models/signup-payload.model';
import { SignupResponse } from '../models/signup-response.model';
import { OrgStorageService } from './org-storage.service';
import { RouterApiService } from './router-api.service';
import { TabStorageService } from './tab-storage.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class RouterAuthService {
  constructor(
    private routerApiService: RouterApiService,
    private orgStorageService: OrgStorageService,
    private tokenService: TokenService,
    private tabStorageService: TabStorageService,
    @Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>,
  ) {}

  private getTrimmedEmail(email: string): string {
    return email.trim().toLowerCase();
  }

  private setClusterDomain(domain: string) {
    this.rootUrl.next(domain);
    this.tokenService.setClusterDomain(domain);
  }

  private newRefreshToken(refreshToken: string) {
    this.orgStorageService.remove('user');
    this.orgStorageService.remove('in_app_chat_restore_id');
    this.tokenService.setRefreshToken(refreshToken);
  }

  private fetchAccessToken(refreshToken: string): Observable<AuthResponse> {
    // this function is called from multiple places, token should be returned and not saved from here
    const accessToken = this.tokenService.getAccessToken();
    const payload: AuthPayload = {
      refresh_token: refreshToken,
      access_token: accessToken,
    };
    return this.routerApiService.post<AuthResponse>('/auth/access_token', payload);
  }

  handleSignInResponse(data: SignInResponse): Observable<SignInResponse> {
    /* org_id is not required for API's like reset_password, etc */
    if (data.org_id) {
      this.tabStorageService.set('org_id', data.org_id);
    }
    this.newRefreshToken(data.refresh_token);
    this.setClusterDomain(data.cluster_domain);

    let obs$: Observable<SignInResponse> = {} as Observable<SignInResponse>;
    const accessToken = this.tokenService.getAccessToken();

    if (accessToken) {
      obs$ = of(data);
    } else {
      return this.fetchAccessToken(data.refresh_token).pipe(
        map((resp) => {
          this.tokenService.setAccessToken(resp.access_token);
          return data;
        }),
      );
    }

    return obs$;
  }

  resendVerification(email: string, orgId?: string): Observable<ResendEmailVerificationResponse> {
    const trimmedEmail = this.getTrimmedEmail(email);
    const payload: ResendEmailVerificationPayload = {
      email: trimmedEmail,
      org_id: orgId,
    };

    return this.routerApiService.post<ResendEmailVerificationResponse>('/auth/resend_email_verification', payload);
  }

  sendResetPassword(email: string): Observable<Record<string, string>> {
    const trimmedEmail = this.getTrimmedEmail(email);
    const payload: SendResetPasswordPayload = {
      email: trimmedEmail,
    };

    return this.routerApiService.post<Record<string, string>>('/auth/send_reset_password', payload);
  }

  checkIfFreeDomain(email: string): boolean {
    const domainList = ['hotmail.com', 'rediffmail.com', 'yahoo.com', 'outlook.com'];
    const domain = email.split('@')[1];
    return domainList.includes(domain.toLowerCase());
  }

  canSignup(email: string): Observable<Record<string, string>> {
    const trimmedEmail = this.getTrimmedEmail(email);
    const payload: CanSignupPayload = {
      email: trimmedEmail,
    };
    return this.routerApiService.post<Record<string, string>>('/auth/basic/can_signup', payload);
  }

  checkEmailExists(email: string): Observable<Record<string, string>> {
    const trimmedEmail = this.getTrimmedEmail(email);
    return this.routerApiService.post<Record<string, string>>('/auth/basic/email_exists', {
      email: trimmedEmail,
    });
  }

  /* eslint-disable max-params-no-constructor/max-params-no-constructor */
  basicSignup(
    email: string,
    fullName: string,
    title: string,
    mobile: string,
    signupParams: SignupParams,
    persona: string,
    password: string,
    region: string,
  ): Observable<SignupResponse> {
    const trimmedEmail = this.getTrimmedEmail(email);
    const payload: SignupPayload = {
      email: trimmedEmail,
      password,
      title,
      mobile,
      signup_params: signupParams,
      full_name: fullName,
      persona,
      region,
    };
    return this.routerApiService.post<SignupResponse>('/auth/basic/signup', payload);
  }

  basicSignin(email: string, password: string): Observable<SignInResponse> {
    const trimmedEmail = this.getTrimmedEmail(email);
    const payload: SignInPayload = {
      email: trimmedEmail,
      password,
    };
    return this.routerApiService
      .post<SignInResponse>('/auth/basic/signin', payload)
      .pipe(switchMap((resp) => this.handleSignInResponse(resp)));
  }

  resetPassword(refreshToken: string, password: string): Observable<SignInResponse> {
    const payload: ResetPasswordPayload = {
      refresh_token: refreshToken,
      password,
    };
    return this.routerApiService
      .post<SignInResponse>('/auth/reset_password', payload)
      .pipe(switchMap((resp) => this.handleSignInResponse(resp)));
  }

  emailVerify(verificationCode: string): Observable<SignInResponse> {
    const payload: EmailVerifyPayload = {
      verification_code: verificationCode,
    };
    return this.routerApiService
      .post<SignInResponse>('/auth/email_verify', payload)
      .pipe(switchMap((resp) => this.handleSignInResponse(resp)));
  }

  verifySecondaryEmail(verificationCode: string): Observable<SecondaryEmailVerifyResponse> {
    const payload: SecondaryEmailVerifyPayload = {
      email_id: verificationCode,
    };
    return this.routerApiService.post<SecondaryEmailVerifyResponse>('/auth/secondary_email_verify', payload);
  }

  getRegions(): Observable<RegionResponse> {
    return this.routerApiService.get<RegionResponse>('/regions');
  }

  googleSignin(accessToken: string): Observable<SignInResponse> {
    const payload: GoogleSignInPayload = {
      access_token: accessToken,
    };
    return this.routerApiService
      .post<SignInResponse>('/auth/google/signin', payload)
      .pipe(switchMap((resp) => this.handleSignInResponse(resp)));
  }
}
