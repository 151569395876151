import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { PasswordStrength } from './password-rules.interface';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss'],
})
export class PasswordRulesComponent implements OnInit {
  @Input() passwordStrength: ValidationErrors;

  errorMapping: {
    type: keyof PasswordStrength;
    message: string;
  }[];

  constructor() {}

  private createFormErrorMapping() {
    this.errorMapping = [
      {
        type: 'hasCharacterLimit',
        message: 'is between 12 to 32 characters',
      },
      {
        type: 'hasUppercaseCharacter',
        message: 'contains at least 1 uppercase character',
      },
      {
        type: 'hasLowercaseCharacter',
        message: 'contains at least 1 lowercase character',
      },
      {
        type: 'hasDigit',
        message: 'contains at least 1 number',
      },
      {
        type: 'hasSpecialCharacter',
        message: 'contains at least 1 special character',
      },
    ];
  }

  ngOnInit() {
    this.createFormErrorMapping();
  }
}
