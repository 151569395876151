import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-svg-sprite',
  templateUrl: './svg-sprite.component.html',
  styleUrls: ['./svg-sprite.component.scss'],
})
export class SvgSpriteComponent implements OnInit, AfterViewInit {
  @ViewChild('svg') svgElement: ElementRef;

  @Input() icon: string;

  @Input() classes: string;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const icon = this.document.getElementById(this.icon);
    let viewBox = '0 0 16 16'; // Defaulting view box

    // If SVG has loaded then get viewBox of the icon
    if (icon) {
      viewBox = icon.getAttribute('viewBox');
    }
    this.renderer.setAttribute(this.svgElement.nativeElement, 'viewBox', viewBox);
  }

  ngOnInit() {}
}
