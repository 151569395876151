import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestOptions } from '../models/http-request-options.model';
import { HttpClient } from '@angular/common/http';
import { ROOT_URL } from '../../root-url.token';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(@Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>, private httpClient: HttpClient) {}

  get<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${this.rootUrl.value}/api${url}`;
    return this.httpClient.get<T>(apiPath, config);
  }

  post<T>(url: string, body: Record<string, any> = {}, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${this.rootUrl.value}/api${url}`;
    return this.httpClient.post<T>(apiPath, body, config);
  }

  delete<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${this.rootUrl.value}/api${url}`;
    return this.httpClient.delete<T>(apiPath, config);
  }
}
