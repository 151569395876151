import { Inject, Injectable } from '@angular/core';
import { Observable, map, switchMap, throwError, BehaviorSubject } from 'rxjs';
import { AuthPayload } from '../models/auth-payload.model';
import { AuthResponse } from '../models/auth-response.model';
import { ExtendedOrgUserResponse } from '../models/extended-org-user-response.model';
import { ExtendedOrgUser } from '../models/extended-org-user.model';
import { UserRole } from '../models/user-role.enum';
import { ApiService } from './api.service';
import { DataTransformService } from './data-transform.service';
import { TokenService } from './token.service';
import { CookieService } from 'ngx-cookie-service';
import { OrgStorageService } from './org-storage.service';
import { UserStorageService } from './user-storage.service';
import { TabStorageService } from './tab-storage.service';
import { ROOT_URL } from '../../root-url.token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>,
    private apiService: ApiService,
    private tokenService: TokenService,
    private dataTransformService: DataTransformService,
    private orgStorageService: OrgStorageService,
    private userStorageService: UserStorageService,
    private cookieService: CookieService,
    private tabStorageService: TabStorageService
  ) {}

  isLoggedIn(): boolean {
    return (
      !!this.tokenService.getAccessToken() &&
      !!this.tokenService.getRefreshToken() &&
      !!this.tokenService.getClusterDomain()
    );
  }

  clearBasicOrgStorage() {
    this.orgStorageService.remove('user');
    this.orgStorageService.remove('in_app_chat_restore_id');
  }

  refreshEou(): Observable<ExtendedOrgUser> {
    return this.apiService.get<ExtendedOrgUserResponse>('/eous/current').pipe(
      map((data) => {
        const extendedOrgUser: ExtendedOrgUser = this.dataTransformService.unflatten<ExtendedOrgUser>(data);
        this.orgStorageService.set('user', extendedOrgUser);
        return extendedOrgUser;
      })
    );
  }

  setTokensFromCookies(orgId: string): Observable<string | ExtendedOrgUser> {
    const refreshToken = this.cookieService.get('refresh_token');
    const accessToken = this.cookieService.get('access_token');
    const clusterDomain = this.cookieService.get('cluster_domain');
    this.tabStorageService.set('org_id', orgId);

    this.clearBasicOrgStorage();

    /**
     * This ideally shouldn't be needed because the
     * setRefreshToken and setAccessToken methods
     * overwrite the existing data
     */
    this.tokenService.resetRefreshToken();
    this.tokenService.resetAccessToken();

    if (refreshToken && accessToken && clusterDomain) {
      this.tokenService.setClusterDomain(clusterDomain);
      this.tokenService.setRefreshToken(refreshToken);
      this.tokenService.setAccessToken(accessToken);
      this.rootUrl.next(clusterDomain);

      return this.refreshEou();
    } else {
      return throwError(() => 'Something went wrong');
    }
  }

  getNewAccessToken(refreshToken: string): Observable<string> {
    this.clearBasicOrgStorage();
    this.tokenService.resetAccessToken();
    this.tokenService.setRefreshToken(refreshToken);
    const accessToken = this.tokenService.getAccessToken();

    const params: AuthPayload = {
      refresh_token: refreshToken,
      access_token: accessToken,
    };

    return this.apiService.post<AuthResponse>('/auth/access_token', params).pipe(
      switchMap((res) => {
        this.tokenService.setAccessToken(res.access_token);
        return this.refreshEou();
      }),
      map(() => this.tokenService.getAccessToken())
    );
  }

  getEou(): ExtendedOrgUser {
    return this.orgStorageService.get('user');
  }

  getRoles(): UserRole[] {
    const currentUser = this.getEou();
    return currentUser.ou.roles;
  }

  logout(): Observable<Record<string, unknown>> {
    this.orgStorageService.removeKeysOnLogout();
    this.userStorageService.removeKeysOnLogout();
    this.tabStorageService.removeAll();
    return this.apiService.post<Record<string, unknown>>('/auth/logout');
  }
}
