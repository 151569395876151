import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogTheme } from './dialog-theme.enum';
import { DialogType } from './dialog-type.enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  title: string;

  type: DialogType;

  consequences: string[];

  label: string;

  placeholder: string;

  required: boolean;

  requiredErrorMessage: string;

  lengthErrorMessage: string;

  inputType: string;

  ariaLabel: string;

  confirmationMsg: string;

  okButtonText: string;

  cancelButtonText: string;

  initialValue: string;

  isPrompt: boolean;

  titleTheme: DialogTheme;

  prompt: FormControl;

  dialogTypes: typeof DialogType = DialogType;

  constructor(private dialogConfig: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {
    this.prompt = new FormControl('', Validators.maxLength(250));
  }

  /*
   * TODO :
   * Add word count for textarea field.
   * Add error icon to be shown when their is a error.
   */

  close(action: string) {
    this.dialogRef.close(action);
  }

  hide() {
    if (this.prompt.valid) {
      this.dialogRef.close('hide');
    }
  }

  ngOnInit() {
    ({
      title: this.title,
      type: this.type,
      titleTheme: this.titleTheme,
      consequences: this.consequences,
      label: this.label,
      placeholder: this.placeholder,
      required: this.required,
      requiredErrorMessage: this.requiredErrorMessage,
      lengthErrorMessage: this.lengthErrorMessage,
      inputType: this.inputType,
      ariaLabel: this.ariaLabel,
      confirmationMsg: this.confirmationMsg,
      okButtonText: this.okButtonText,
      cancelButtonText: this.cancelButtonText,
      initialValue: this.initialValue,
    } = this.dialogConfig.data);
    if (this.required) {
      this.prompt.addValidators(Validators.required);
    }
    this.isPrompt = this.type === DialogType.PROMPT;

    if (this.isPrompt) {
      if (this.initialValue) {
        this.prompt.setValue(this.initialValue);
      } else {
        this.prompt.setValue('');
      }

      if (!this.inputType) {
        this.inputType = 'input';
      }
    }

    if (!this.titleTheme) {
      switch (this.type) {
        case DialogType.PROMPT:
          this.titleTheme = DialogTheme.INFO;
          break;

        case DialogType.CONFIRM:
          this.titleTheme = DialogTheme.WARN;
          break;

        case DialogType.ALERT:
          this.titleTheme = DialogTheme.DANGER;
          break;
      }
    }
  }
}
