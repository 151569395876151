import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestOptions } from '../models/http-request-options.model';
import { HttpClient } from '@angular/common/http';
import { ROOT_URL } from '../../root-url.token';

@Injectable({
  providedIn: 'root',
})
export class PlatformApiService {
  constructor(
    @Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>,
    private httpClient: HttpClient,
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  post<T>(url: string, body: Record<string, any> = {}, config?: HttpRequestOptions): Observable<T> {
    const apiPath = `${this.rootUrl.value}/platform/v1${url}`;
    return this.httpClient.post<T>(apiPath, body, config);
  }
}
