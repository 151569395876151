import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, throwError } from 'rxjs';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { ToastMessageService } from '../../core/services/toast-message.service';
import { PendingVerificationQueryParams } from './pending-verification-query-params.interface';
import { TargetAppConfigService } from '../../core/services/target-app-config.service';
import { TargetAppConfig } from '../../core/models/target-app-config.model';

@Component({
  selector: 'app-pending-verification',
  templateUrl: './pending-verification.component.html',
  styleUrls: ['./pending-verification.component.scss'],
})
export class PendingVerificationComponent implements OnInit {
  email: string;

  isVerificationEmailSent: boolean;

  emailForm: FormControl;

  showLoaderOnButton: boolean;

  hasTokenExpired: boolean;

  title: string;

  private queryParams: PendingVerificationQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerAuthService: RouterAuthService,
    private toastMessageService: ToastMessageService,
    private targetAppConfigService: TargetAppConfigService
  ) {
    this.emailForm = new FormControl('', [Validators.required, Validators.email]);
  }

  resendVerification() {
    if (this.emailForm.invalid) {
      return;
    }
    this.showLoaderOnButton = true;
    this.routerAuthService
      .resendVerification(this.emailForm.value, this.queryParams.org_id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 422) {
            this.router.navigate(['disabled']);
          } else {
            this.toastMessageService.showErrorToast(
              `Oops! We couldn't find the email entered or something went wrong, please try again.`
            );
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.showLoaderOnButton = false;
        })
      )
      .subscribe((resp) => {
        this.isVerificationEmailSent = true;
      });
  }

  ngOnInit() {
    this.queryParams = this.activatedRoute.snapshot.queryParams as PendingVerificationQueryParams;
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    this.email = this.queryParams.email;
    this.hasTokenExpired = this.queryParams.has_token_expired;
    if (this.email) {
      this.emailForm.setValue(this.email);
    }
    this.title = this.hasTokenExpired ? 'Verification link expired' : 'Please verify your email';
  }
}
