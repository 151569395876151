<div class="auth-container">
  
  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card">

      <div class="icon-container">
        <app-svg-sprite icon="mail-outline" classes="tw-h-64-px"></app-svg-sprite>
      </div>

      <div *ngIf="!isVerificationEmailSent" class="content-container">
        <h3 class="title">{{ title }}</h3>
        <p *ngIf="!hasTokenExpired" class="description">
          An activation link has been sent to

          <strong *ngIf="email" class="tw-text-16-px"> {{ email }}</strong>
          <span *ngIf="!email" class="tw-text-16-px"> your email. </span>

          Please click on it to verify your account and proceed with {{ targetConfig.BRAND_NAME }}.
        </p>

        <p *ngIf="!hasTokenExpired" class="description-2">
          Didn't receive the email?
          <a *ngIf="email" (click)="resendVerification()" class="tw-cursor-pointer tw-text-16-px">Resend</a>
          <span *ngIf="!email" class="tw-text-16-px">Please use the below form to request it again.</span>
        </p>

        <p *ngIf="hasTokenExpired" class="description-2">
          Please use the below form to request the verification link again.
        </p>

        <form *ngIf="!email" class="form-container" novalidate>

          <div class="email-input-container">
            <input
              [formControl]="emailForm"
              autocomplete="off" 
              type="email" 
              placeholder="Email"
              class="input-text input-content"
              [ngClass]="{'p-error' : emailForm.touched && emailForm.invalid}"
              appAutofocus>

            <div class="error-space">
            </div>

            <ng-container *ngIf="emailForm.touched && emailForm.invalid">

              <div *ngIf="emailForm.hasError('required')" class="error">Please enter an email address</div>
              <div *ngIf="emailForm.hasError('email')" class="error">Please enter a valid email address</div>

            </ng-container>

          </div>

          <button
            pButton
            type="submit" 
            class="button button-primary" 
            (click)="resendVerification()"
            [isLoading]="showLoaderOnButton"
            [loadingText]="'Resending'"
            label="Resend Verification Link"
            appFormValidationButtonLoader
            >
          </button>
        </form>
      </div>

      <div *ngIf="isVerificationEmailSent" class="tw-text-center">
        <h3 class="title">Verification email sent</h3>

        <p class="description">
          An activation link has been sent to
          <strong *ngIf="email" class="tw-text-16-px"> {{ email }}</strong>
          <span *ngIf="!email" class="tw-text-16-px"> your email. </span>
          Please click on it to verify your account and proceed with {{ targetConfig.BRAND_NAME }}.
        </p>
      </div>
    </div>

    <div class="alternate-text">
      Back to <a href="#/signin?asset=webapp">Sign In</a>
    </div>
  </div>
</div>

<app-toast-message></app-toast-message>