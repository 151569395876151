import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { ExtendedOrgUser } from '../../core/models/extended-org-user.model';
import { UserRole } from '../../core/models/user-role.enum';
import { AuthService } from '../../core/services/auth.service';
import { OrgStorageService } from '../../core/services/org-storage.service';
import { OrgUserService } from '../../core/services/org-user.service';
import { OrgService } from '../../core/services/org.service';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { TrackingService } from '../../core/services/tracking.service';
import { VerifyQueryParams } from './verify-query-params.interface';
import { TargetAppConfigService } from '../../core/services/target-app-config.service';
import { TargetAppConfig } from '../../core/models/target-app-config.model';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  private queryParams: VerifyQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerAuthService: RouterAuthService,
    private authService: AuthService,
    private trackingService: TrackingService,
    private orgService: OrgService,
    private orgUserService: OrgUserService,
    private orgStorageService: OrgStorageService,
    private targetAppConfigService: TargetAppConfigService
  ) {}

  private updateRedirectUrlAndOrgId(): { fyle_redirect_url: string; org_id: string } {
    let fyleRedirectUrl = this.queryParams.fyle_redirect_url;
    let orgId = this.queryParams.org_id;

    if (this.queryParams.state) {
      try {
        // During SSO login flow, the state is set as `WEB` which is already a decoded string.
        const decodedState = JSON.parse(atob(this.queryParams.state));
        fyleRedirectUrl = decodedState.fyle_redirect_url;
        orgId = decodedState.org_id;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    }
    return { fyle_redirect_url: fyleRedirectUrl, org_id: orgId };
  }

  private handleRedirection(): Observable<null> {
    const queryParams = {
      ...this.updateRedirectUrlAndOrgId(),
    };

    this.router.navigate(['switch_org'], { queryParams });
    return of(null);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 422) {
      this.router.navigate(['disabled']);
    } else if (error.status === 440) {
      this.router.navigate(['pending_verification'], {
        queryParams: {
          has_token_expired: true,
          org_id: this.queryParams.org_id,
        },
      });
    } else {
      this.router.navigate(['logout']);
    }
  }

  private handleVerificationCodeFlow(verificationCode: string) {
    this.routerAuthService
      .emailVerify(verificationCode)
      .pipe(
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        }),
        tap(() => this.authService.clearBasicOrgStorage()),
        switchMap(() => this.authService.refreshEou()),
        tap(() => {
          this.trackingService.emailVerified();
        }),
        switchMap(() => {
          const eou = this.authService.getEou();
          if (eou.ou.roles.includes(UserRole.OWNER)) {
            return this.orgService.getCurrentOrg().pipe(
              switchMap(() => this.orgUserService.markActive()),
              tap(() => {
                this.trackingService.activated();
                this.orgStorageService.set('admin_onboarding_shown', false);
              }),
              switchMap(() => this.handleRedirection())
            );
          }
          return this.handleRedirection();
        })
      )
      .subscribe();
  }

  private setExtendedOrgUser(): Observable<ExtendedOrgUser> {
    let eou$: Observable<ExtendedOrgUser>;
    const eou = this.authService.getEou();
    if (eou) {
      eou$ = of(eou);
    } else {
      eou$ = this.authService.refreshEou();
    }
    return eou$;
  }

  private setTokensFromCookies() {
    let isOrgIdDecodedFromStateQP = true;
    const queryParams = this.updateRedirectUrlAndOrgId();
    if (!this.queryParams.state) {
      isOrgIdDecodedFromStateQP = false;
      queryParams.org_id = this.queryParams.org_id;
    }
    /** Set the tokens from cookies for SSO enabled orgs */
    this.authService
      .setTokensFromCookies(queryParams.org_id)
      .pipe(
        catchError((error) => {
          this.router.navigate(['logout']);
          return throwError(() => error);
        })
      )
      .subscribe((resp) => {
        const eou = this.authService.getEou();
        this.trackingService.onSignin(eou.us.email);

        if (isOrgIdDecodedFromStateQP) {
          this.router.navigate(['switch_org'], {
            queryParams: {
              asset: this.queryParams.asset,
              ...queryParams,
            },
          });
        } else {
          this.router.navigate(['switch_org'], {
            queryParams: {
              asset: this.queryParams.asset,
              fyle_redirect_url: queryParams.fyle_redirect_url,
            },
          });
        }
      });
  }

  private handleNonVerificationCodeFlow() {
    if (!this.authService.isLoggedIn()) {
      this.setTokensFromCookies();
    } else {
      this.setExtendedOrgUser()
        .pipe(
          catchError((error) => {
            this.router.navigate(['logout']);
            return throwError(() => error);
          })
        )
        .subscribe((res) => {
          this.router.navigate(['switch_org'], {
            queryParams: {
              asset: this.queryParams.asset,
              ...this.updateRedirectUrlAndOrgId(),
            },
          });
        });
    }
  }

  ngOnInit() {
    this.queryParams = this.activatedRoute.snapshot.queryParams as VerifyQueryParams;
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    const verificationCode = this.queryParams.verification_code;

    if (verificationCode) {
      this.handleVerificationCodeFlow(verificationCode);
    } else {
      this.handleNonVerificationCodeFlow();
    }
  }
}
