<p-toast
  position="bottom-center"
  (onClose)="onClose()"
  [baseZIndex]="5000"
>
  <ng-template let-message pTemplate="message">
    <div class="message-container">
      <app-svg-sprite
        icon="{{message.icon}}"
        class="toast-message-icon">
      </app-svg-sprite>
      <span class="toast-text">{{ message.detail }}</span>
    </div>

    <div class="action-container">
      <span  *ngIf="message?.data?.actionText" class="action" (click)="message.data.actionToDo()">
        {{ message.data.actionText }}
      </span>
      <app-svg-sprite
        icon="times"
        class="toast-close-icon"
        (click)="onClose()">
      </app-svg-sprite>
    </div>
  </ng-template>
</p-toast>