<div class="auth-container">

  <div class="header-container">
    <app-header [iconUrl]="targetConfig.FULL_LOGO_URL_WHITE"></app-header>
  </div>

  <div class="message-input-container">

    <div class="signup-container">

      <div class="message-container">
        <h1 class="message">
          {{signUpMessage.line1}}
          {{signUpMessage.line2}}
          <span class="img-container">
            <img src="./assets/images/thunderbolt.png" alt="Bolt" class="icon-bolt">
          </span>
        </h1>
      </div>

      <div class="content-container">
        <div class="auth-card">

          <h3 class="page-title">Get Started</h3>
          <p class="description">We just need your work email address.</p>

          <form novalidate class="signup-form-container">

            <div class="tw-flex tw-flex-col">
              
              <div *ngIf="errorState" class="error-message-box">
                <span *ngIf="errorState === signupErrorState.UNAUTHORIZED">
                  Unable to signup. If you already have a {{ targetConfig.BRAND_NAME }} account? 
                  <a class="sign-in-text" href="#/signin?email={{emailForm.value}}">Try Sign In?</a>
                </span>
                <span *ngIf="errorState === signupErrorState.UNKNOWN_ERROR">Something went wrong, please try again later</span>
              </div>

              <div class="email-input-container">
                <input 
                  [formControl]="emailForm"
                  name="email"
                  type="email"
                  class="email-input input-text"
                  placeholder="Work Email"
                  [ngClass]="{'p-error': emailForm.touched && emailForm.invalid}"
                  appAutofocus>

                <div class="error-space">
                </div>

                <ng-container *ngIf="emailForm.touched && emailForm.errors">
                  <div *ngIf="emailForm.hasError('required')" class="error">Please enter an email</div>
                  <div *ngIf="emailForm.hasError('email')" class="error">
                    Please enter a valid email address
                  </div>
                </ng-container>
                
                <div *ngIf="isFreeDomainEmail" class="error">
                  Please use your business email to signup
                </div>
              </div>

              <button
                appFormValidationButtonLoader
                [disabled]="isFreeDomainEmail"
                [isLoading]="showLoaderOnButton"
                [loadingText]="'Proceeding'"
                pButton
                type="submit" 
                class="next-button button button-primary"
                (click)="basicAuthSignup()" 
                label="Proceed"
              >
              </button>
            </div>
          </form>
        </div>

        <p class="action-alternate">
          Already on {{ targetConfig.BRAND_NAME }}? <a class="tw-cursor-pointer sign-in" (click)="goToSignIn()">Sign In</a>
        </p>

      </div>
    </div>
  </div>
</div> 