<div *ngIf="orgs.length > 1" class="switch-org-container-new">
  <div class="switch-org-container-nav">
    <div class="back-button-org-logo">
      <div class="cursor-pointer left-icon-container" (click)="goBack()">
        <app-svg-sprite icon="arrow-left" classes="tw-w-20-px tw-mr-16-px tw-fill-baltic-sea"></app-svg-sprite>
      </div>
      <a (click)="switchToOrg(activeOrg)">
        <img src="{{this.targetConfig.FULL_LOGO_URL_BLACK}}" alt="{{ targetConfig.BRAND_NAME }}" class="fyle-logo">
      </a>
      
    </div>
    <div class="sign-out-container" (click)="logout()">
      <app-svg-sprite icon="power-menu" class="common-icon sign-out-icon"></app-svg-sprite>
      <span class="tw-text-free-speech-red"><strong>Sign Out</strong></span>
    </div>
  </div>

  <app-active-org-card
    [isLoading]="isLoading"
    [activeOrg]="activeOrg"
    [isPrimary]="activeOrg?.id === primaryOrg?.id"
    (activeOrgClick)="onActiveOrgClick()"
  />
  <div class="organization-list">
    <div class="org-header-search-box">
      <div [ngClass]="{'hide-header' : showSearchBar}">
        <div class="org-header">Switch Organization</div>
        <div class="org-sub-header">Select the organization you want to switch to</div>
      </div>
      <div *ngIf="isLoading" class="mt-auto mb-auto">
        <app-shimmers 
          class="switch-org-icon-search-shimmer" 
          [height]="36" 
          [width]="38">
        </app-shimmers>
      </div>
  
      <div *ngIf="!isLoading" class="top-container" [ngClass]="{'max-lg:tw-w-full' : showSearchBar}">
        <div class="search-box">
          <app-simple-search
            [value]="searchText"
            [debounce]="300"
            (inputChange)="onInputChange($event)"
            (searchBoxState)="onSearchBoxStateChange($event)"
            [showSearchBar]="showSearchBar"
            class="max-lg:tw-w-full"/>

          <div *ngIf="showSearchBar" (click)="toggleSearchBar(false)" class="cancel">Cancel</div>
        </div>
      </div>
    </div>

    <div class="orgs" *ngIf="!isLoading" [ngClass]="{'tw-w-3/10' : this.filteredOrgs.length === 0, 'tw-w-4/10' : this.filteredOrgs.length > 0 }">
      <p-listbox [options]="filteredOrgs" styleClass="tw-border-0 tw-bg-white-smoke-2" (onClick)="switchToOrg($event.option, 'User switched')">

        <ng-template let-org pTemplate="item">

          <div class="org-info">
            <div class="org-currency-name-info">
              <div class="currency-icon">
                <span>{{ org.currency }}</span>
              </div>
              <div class="org-name-domain">
                <h3 class="org-name">{{ org.name }}</h3>
                <h4 class="org-domain">{{ org.domain }}</h4>
              </div>
              <div *ngIf="org.id === primaryOrg.id" class="primary-text">Primary Org</div>
            </div>
          </div>

        </ng-template>

        <ng-template pTemplate="empty">

          <div class="empty-org-state">
            <app-svg-sprite icon="no-org-found" class="no-org-found-icon"></app-svg-sprite>
            <div class="no-result-found">Sorry, no results found!</div>
            <div class="tw-text-comet empty-message-1">We could not find what you were looking for</div>
            <div class="tw-text-comet empty-message-2">Kindly check the keywords again</div>
          </div>
          
        </ng-template>

      </p-listbox>
    </div>

    <div class="orgs-shimmer" *ngIf="isLoading">
      <p-listbox [options]="orgs" styleClass="tw-border-0 tw-bg-white-smoke-2">

        <ng-template let-index="index" pTemplate="item">

          <div class="org-info">
            <div class="org-currency-name-info">
              <div class="currency-icon-shimmer">
                <app-shimmers 
                  [width]="46" 
                  [height]="46">
                </app-shimmers>
              </div>
              <div class="org-name-domain">
                <app-shimmers 
                  [height]="18" 
                  [width]="200">
                </app-shimmers>
                <app-shimmers 
                  [height]="18" 
                  [width]="120">
                </app-shimmers>
              </div>
              <app-shimmers *ngIf="index === 0" 
                [height]="24" 
                [width]="88"
                class="primary-text-shimmer">
              </app-shimmers>
            </div>
          </div>
        </ng-template>

      </p-listbox>
    </div>

  </div>
</div>
<app-toast-message></app-toast-message>