<div class="sign-in auth-container">
  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card">
      <h3 class="title">Sign In</h3>
      <p class="sub-title">Please enter your credentials to access your account</p>

      <form #signInFormElement [formGroup]="signInForm" novalidate class="sign-in-form">

        <ng-container *ngIf="errorState && errorState !== signInErrorState.ACCOUNT_LOCKED && doesEmailExist">

          <div class="info-message-box info-warning-box">

            <div *ngIf="errorState === signInErrorState.UNAUTHORIZED && doesEmailExist" class="info-dialog-box tw-flex">
              <app-svg-sprite icon="warning" classes="tw-h-18-px"></app-svg-sprite>
              <span class="tw-flex tw-flex-col tw-ml-8-px">
                <span class="password-error-title">Account will be locked after 5 unsuccessful attempts</span>
                <span class="password-error-subtitle">Your account will be temporarily locked</span>
              </span>
            </div>

            <div *ngIf="errorState === signInErrorState.UNKNOWN_ERROR" class="info-dialog-box tw-flex">
              <app-svg-sprite icon="warning" classes="tw-h-18-px"></app-svg-sprite>
              <span class="unknown-error-text">Something went wrong, please try again later</span>
            </div>
          </div>
        </ng-container>
          
        <div *ngIf="errorState === signInErrorState.ACCOUNT_LOCKED" class="info-message-box info-warning-box info-error-box">
          <div class="info-dialog-box">
            <app-svg-sprite icon="error-circle" classes="tw-h-18-px tw-fill-fire-engine-red"></app-svg-sprite>
            <span class="tw-flex tw-flex-col tw-ml-10-px">
              <span class="account-locked-title">Try logging in after 5 minutes</span>
              <span class="account-locked-subtitle">This account is temporarily locked due to multiple unsuccessful login attempts.</span>
            </span>
          </div>
        </div>

        <div *ngIf="errorState === signInErrorState.GOOGLE_ACCESS_DENIED" class="info-message-box info-warning-box info-google-error-box">
          <div class="info-dialog-box">
            <app-svg-sprite icon="error-circle" classes="tw-h-18-px tw-fill-fire-engine-red"></app-svg-sprite>
            <span class="tw-flex tw-flex-col tw-ml-14-px">
              <span class="tw-text-14-px tw-mb-4-px tw-text-text-color-3 tw-font-500">Google Account Access Denied</span>
              <span class="tw-text-12-px">Allow access to your google account to sign in with Google.</span>
            </span>
          </div>
        </div>

        <div class="email-input-container">
          <div class="tw-flex tw-flex-row">
            <label class="input-label">Work Email</label>
            <span class="asterisk">*</span>
          </div>
          <div class="email-input tw-relative">
            <input
              formControlName="email"
              type="email"
              name="email"
              appAutofocus
              placeholder="Enter your business email here"
              class="input-content input-text"
              [ngClass]="{'p-error' : signInForm.controls.email.touched && signInForm.controls.email.invalid }"
            >

            <div *ngIf="!doesEmailExist" class="error-space">
            </div>

            <ng-container *ngIf="signInForm.controls.email.valid && !doesEmailExist && !showNoEmailError">
              <div class="action-wrapper">
                <span class="action-text">
                  <app-svg-sprite icon="check" classes="common-icon tw-fill-white tw-bg-sea-green tw-rounded-1/2 tw-p-4-px"></app-svg-sprite>
                </span>
              </div>
            </ng-container>

            <div *ngIf="doesEmailExist" class="action-wrapper">
              <span class="action-text">
                <span class="change-text" (click)="onEmailChange()">Change</span>
              </span>
            </div>

            <div *ngIf="showNoEmailError && !doesEmailExist" class="action-wrapper">
              <span class="action-text">
                <app-svg-sprite icon="error-circle" classes="common-icon tw-fill-fire-engine-red tw-bg-white tw-rounded-1/2"></app-svg-sprite>
              </span>
            </div>

            <ng-container *ngIf="signInForm.controls.email.touched && signInForm.controls.email.hasError('email')">
              <div class="action-wrapper">
                <span class="action-text">
                  <app-svg-sprite icon="error-circle" classes="tw-p-0 common-icon tw-fill-fire-engine-red"></app-svg-sprite>
                </span>
              </div>
            </ng-container>

          </div>


          <ng-container *ngIf="signInForm.controls.email.touched && signInForm.controls.email.errors">
            <div *ngIf="signInForm.controls.email.hasError('required')" class="error">Please enter an email address</div>
            <div *ngIf="signInForm.controls.email.hasError('email')" class="error">Please enter a valid email address</div>
          </ng-container>

          <div *ngIf="errorState && errorState !== signInErrorState.GOOGLE_ACCESS_DENIED && !doesEmailExist" class="info-message-box"
            [ngClass]="{'tw-animate-blinker' : showNoEmailError}">
            
            <div *ngIf="errorState === signInErrorState.UNKNOWN_ERROR" class="info-dialog-box tw-flex">
              <app-svg-sprite icon="info-circle-new" classes="tw-h-18-px"></app-svg-sprite>
              <span class="server-error-text">Something went wrong, please try again later</span>
            </div>
          </div>
          
        </div>

        <button *ngIf="!doesEmailExist"
          appFormValidationButtonLoader
          [isLoading]="showLoaderOnButton"
          [loadingText]="'Validating'"
          [formToValidate]="signInForm"
          [formElement]="signInFormElement"
          pButton
          type="submit" 
          class="next-button button button-primary"
          (click)="checkIfEmailExists()" 
          label="Next"
        >
        </button>

        <ng-container *ngIf="doesEmailExist">
          <div class="password-container tw-mt-10-px">
            <div class="password-label tw-flex tw-items-end tw-justify-between">
              <div class="tw-flex tw-flex-row">
                <label class="input-label">Password</label>
                <span class="asterisk">*</span>
              </div>
              <p class="forgot-password" (click)="goToResetPassword()">Forgot password?</p>
            </div>
            <div class="password-input tw-relative">
              <input
                formControlName="password"
                appAutofocus
                autocomplete="current-password"
                type="{{showPlainPassword ? 'text' : 'password'}}"
                [ngClass]="{'p-error' : signInForm.controls.password.touched && signInForm.controls.password.invalid}"
                placeholder="Enter your password here" 
                class="input-content input-text"
              >
              <div class="error-space">
              </div>

              <ng-container *ngIf="errorState === signInErrorState.UNAUTHORIZED && doesEmailExist && showNoEmailError">
                <div class="action-wrapper info-wrapper tw-mr-32-px">
                  <span class="action-text">
                    <app-svg-sprite icon="error-circle" classes="tw-p-0 tw-p-0 common-icon tw-fill-fire-engine-red"></app-svg-sprite>
                  </span>
                </div>
              </ng-container>

              <div *ngIf="!showPlainPassword" class="action-wrapper hide-icon" (click)="showPlainPassword = !showPlainPassword">
                <span class="action-text">
                  <app-svg-sprite icon="hide" classes="tw-p-0 common-icon tw-cursor-pointer"></app-svg-sprite>
                </span>
              </div>
              <div *ngIf="showPlainPassword" class="action-wrapper view-eye-icon" (click)="showPlainPassword = !showPlainPassword">
                <span class="action-text">
                  <app-svg-sprite icon="view-eye" classes="tw-p-0 common-icon tw-cursor-pointer"></app-svg-sprite>
                </span>
              </div>

              <ng-container *ngIf="errorState === signInErrorState.UNAUTHORIZED && doesEmailExist && showNoEmailError">
                <div class="no-email-err error">
                  The password is incorrect
                </div>
              </ng-container>
              
              <ng-container *ngIf="signInForm.controls.password.touched && signInForm.controls.password.errors">
                <div *ngIf="signInForm.controls.password.hasError('required')" class="error">Please enter your password</div>
              </ng-container>
              
            </div>

            <button pButton
              appFormValidationButtonLoader
              [isLoading]="isSignInApiInProgress"
              [loadingText]="'Signing In'"
              type="submit" 
              class="sign-in-button button button-primary" 
              (click)="basicAuthSignin()" 
              label="Sign in">
            </button>
          </div>
        </ng-container>

        <div *ngIf="!hideGoogleLogin" class="or-text-container">
          <span class="or-text">
            Or
          </span>
        </div>

        <button *ngIf="!hideGoogleLogin" 
          pButton 
          type="submit" 
          class="button button-google" 
          (click)="continueWithGoogle()"
          label="Sign in with Google">

          <div class="tw-rounded-tr-4-px tw-rounded-bl-4-px">
            <img src="./assets/images/google-logo-36x36.png" aria-label="Sign in with Google" alt="Google"
              class="tw-h-24-px tw-w-24-px">
          </div>
        </button>

        <div *ngIf="!isSignInApiInProgress && targetConfig.IS_FYLE_ENV" class="signup-text">New to {{targetConfig.BRAND_NAME}}? <a class="tw-text-navy-blue" href="https://www.fylehq.com/schedule-demo">Schedule a Demo</a>
        </div>

      </form>
    </div>
  </div>
  <!-- <footer *ngIf="targetConfig.IS_FYLE_ENV" class="footer">Never lose track</footer> -->
</div>
