import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SvgSpriteComponent } from '../components/svg-sprite/svg-sprite.component';

@Directive({
  selector: '[appFormValidationButtonLoader]',
})
export class FormValidationButtonLoaderDirective implements OnChanges {
  @Input() loadingText: string;

  @Input() isLoading: boolean;

  @Input() formToValidate: FormGroup;

  @Input() formElement: HTMLFormElement;

  private initialButtonHTML: string;

  private isLoaderAdded: boolean;

  constructor(private element: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {}

  ngOnChanges(): void {
    if (this.formToValidate?.touched) {
      this.verifyFormAndContinue();
    } else {
      this.isLoading ? this.addLoader() : this.removeLoader();
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  onClick(): void {
    if (this.formToValidate) {
      this.verifyFormAndContinue();
    } else {
      this.isLoading ? this.addLoader() : this.removeLoader();
    }
  }

  verifyFormAndContinue(): void {
    if (this.isFormValid()) {
      this.isLoading ? this.addLoader() : this.removeLoader();
    } else {
      return;
    }
  }

  isFormValid(): boolean {
    for (const key in this.formToValidate.controls) {
      if (this.formToValidate.controls.hasOwnProperty(key)) {
        const control = this.formToValidate.get(key);
        /**
         * This works only when we have a form with controls are shown & hidden using *ngIf
         * If display: none; css property is used then this will not work
         */
        if (
          this.formElement.querySelector(`[formControlName="${key}"]`) &&
          control.errors?.required &&
          !control.value
        ) {
          control.markAsTouched();
          return false;
        }
      }
    }
    return true;
  }

  addLoader() {
    if (this.isLoaderAdded) {
      return;
    }

    this.initialButtonHTML = this.element.nativeElement.innerHTML;
    this.element.nativeElement.innerHTML = this.loadingText || this.initialButtonHTML;

    this.renderer.setAttribute(this.element.nativeElement, 'disabled', 'true');
    const componentRef = this.viewContainerRef.createComponent(SvgSpriteComponent);
    componentRef.instance.icon = 'spinner-circle';
    componentRef.instance.classes = 'tw-h-12-px tw-w-12-px tw-ml-8-px tw-animate-spinner tw-fill-white';

    this.renderer.appendChild(this.element.nativeElement, componentRef.location.nativeElement);

    this.isLoaderAdded = true;
  }

  removeLoader() {
    if (this.isLoaderAdded) {
      this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
      this.element.nativeElement.innerHTML = this.initialButtonHTML;
      this.viewContainerRef.clear();
      this.isLoaderAdded = false;
    }
  }
}
