import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GlobalCacheConfig } from 'ts-cacheable';
import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';
import { ExtendedOrgUser } from './app/core/models/extended-org-user.model';

GlobalCacheConfig.maxAge = 10 * 60 * 1000;

function setSentryUserContext(event: Sentry.Event) {
  const orgDataStr = localStorage.getItem('fyle.org_data');
  if (orgDataStr) {
    const orgData = JSON.parse(orgDataStr);
    const orgIds = Object.keys(orgData);
    if (orgIds?.length > 0) {
      const currentOrgId = orgIds[0];
      const eou = orgData[currentOrgId] && (orgData[currentOrgId].user as ExtendedOrgUser);
      if (eou) {
        event.user.id = eou.us.email + ' - ' + eou.ou.id;
        event.user.email = eou.us.email;
        event.user.orgUserId = eou.ou.id;
      }
    }
  } else {
    /* `fyle.user` is fallback key name, in case the user's local storage is not migrated. */
    const eouStr = localStorage.getItem('fyle.user');
    if (eouStr) {
      const eou = JSON.parse(eouStr) as ExtendedOrgUser;
      event.user.id = eou.us.email + ' - ' + eou.ou.id;
      event.user.email = eou.us.email;
      event.user.orgUserId = eou.ou.id;
    }
  }
}

if (environment.SEND_ERROR_TO_SENTRY) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    sampleRate: environment.SENTRY_TRACES_SAMPLE_RATE,
    ignoreErrors: ['Non-Error exception captured'],
    environment: environment.ENVIRONMENT,
    beforeSend(event) {
      setSentryUserContext(event);
      return event;
    },
    release: environment.RELEASE,
  });

  /* adding null scope here so that I am able to edit it in before sending event to sentry
   * This is added because configureScope will not work in beforeSend of sentry
   */
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: null,
      email: null,
      orgUserId: null,
    });
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
