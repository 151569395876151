import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SignInQueryParams } from '../../auth/sign-in/sign-in-query-params.interface';
import { B64CodecService } from './b64-codec.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleSignInService {
  constructor(private b64CodecService: B64CodecService) {}

  getGoogleSignInUrl(stateParams: SignInQueryParams) {
    const clientId = environment.GOOGLE_CLIENT_ID;
    const scope =
      'https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/user.emails.read';

    const state = this.b64CodecService.encode({ auth: 'google', ...stateParams });
    const redirectUri = `${environment.APP_URL}/accounts/assets/static/google_oauth_callback.html`;

    const url = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${redirectUri}&response_type=token&client_id=${clientId}&scope=${scope}&state=${state}`;
    return url;
  }
}
