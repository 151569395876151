import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'environments/environment';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { HttpConfigInterceptor } from './core/interceptors/http-config-interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CookieService } from 'ngx-cookie-service';
import { AuthModule } from './auth/auth.module';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { InitializationService } from './core/services/initialization.service';
import { ROOT_URL } from './root-url.token';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    NgxLocalStorageModule.forRoot({
      prefix: 'fyle',
      delimiter: '.',
    }),
  ],
  providers: [
    MessageService,
    DialogService,
    CookieService,
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS,
    },
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initializationService: InitializationService) => () => initializationService.initConfig(),
      deps: [InitializationService, Sentry.TraceService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ROOT_URL,
      useValue: new BehaviorSubject(environment.ROOT_URL),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
