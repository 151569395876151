<div class="auth-container">
  
  <div class="card-container">
    
    <app-header></app-header>

    <div class="auth-card">
      <p *ngIf="!isSecondaryEmailVerified && !isError">Please wait while we verify your email...</p>

      <p *ngIf="isSecondaryEmailVerified">Your email address has been successfully verified.</p>
      <p *ngIf="isError">Something went wrong while verifying your email address.</p>
      <p *ngIf="isSecondaryEmailVerified || isError">Please wait while you are redirected...</p>

      <p-progressSpinner strokeWidth="5" styleClass="spinner-50"></p-progressSpinner>

    </div>
  </div>
</div>
